import styled from "@emotion/styled"
import { breakpoints, colors, dimensions } from "../styles"
import { Link } from "gatsby"
import { keyframes } from "@emotion/core"

export const NavMenu = styled.nav`
  z-index: 9;
  overflow: visible;
  min-width: 650px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SelectedItemBar = styled.nav`
  width: 50px;
  margin: auto;
  margin-top: 10px;
  height: 2px;
  background: ${colors.redWaskoll};
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
  &.active {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in;
  }
`

export const NavLink = styled.button`
  display: block;
  font-family: Lora;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${colors.darkGrey};
  background: none;
  border: none;
  cursor: pointer;
  outline: 0;
  padding: 0;
  transition: all 0.3s ease-in;
  &.opacity {
    opacity: 0.4;
    transition: all 0.3s ease-in;
  }
`

export const megaMenuEntry = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  max-height: 50px;
  @media (max-width: ${breakpoints.hd}px) {
    width: 100wv;
  }
  padding-top: ${props => props.isSticky ? "18px" : "20px"};
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`