import React from "react"
import Amplify from 'aws-amplify'
import awsConfig from './src/utils/aws-exports'
import { isAuthenticated } from './src/utils/auth'
import WrapWithProvider from "./src/store/wrapWithProvider"
import Layout from "./src/components/layout"

Amplify.configure(awsConfig)

export const wrapRootElement = ({ element}) => {
  return <WrapWithProvider>{element}</WrapWithProvider>
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      {element}
    </Layout>
  )
}

export const onRouteUpdate = (state, page, pages) => {
  isAuthenticated()
    .then(() => console.log("COGNITO SESSION: ACTIVE"))
    .catch(() => {
      console.log("COGNITO SESSION: INACTIVE")
    })
}
