import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import {
  Copyright,
  Footer, LogoWrapper, MenuLink,
  Separator,
  SocialLink,
  WrapperContent,
  WrapperMenu,
  WrapperSocial
} from "./StyledFooter"

import { FaFacebook, FaInstagram } from "react-icons/fa"


export default () => {

  const logoSRC = useStaticQuery(graphql`
      query {
          picto: file(relativePath: { eq: "LOGO_PICTO.png" }) {
              childImageSharp {
                  fixed(height: 25) {
                      ...GatsbyImageSharpFixed_noBase64
                  }
              }
          }
      }
  `)

  return (
    <Footer>
      <WrapperContent>
        <WrapperSocial>
          <SocialLink rel="noreferrer noopener" href="https://www.facebook.com/pages/Waskoll/533934033316630" target="_blank">
            <FaFacebook color="white" size={20}/>
          </SocialLink>
          <SocialLink rel="noreferrer noopener" href="https://www.instagram.com/waskoll_paris/?hl=fr" target="_blank">
            <FaInstagram color="white" size={20}/>
          </SocialLink>
        </WrapperSocial>
        <WrapperMenu>
          <MenuLink to={""}>conditions générales de vente</MenuLink>
          <MenuLink to={""}>Mentions Légales</MenuLink>
          <MenuLink to={""}>Livraison</MenuLink>
          <MenuLink to={""}>Prendre rendez-vous</MenuLink>
        </WrapperMenu>
        <Separator/>
        <LogoWrapper>
          <Img fixed={logoSRC.picto.childImageSharp.fixed}/>
        </LogoWrapper>
        <Copyright>
           © Waskoll {new Date().getFullYear()} - Tous droits réservés
        </Copyright>
      </WrapperContent>
    </Footer>
  )
}
