import styled from "@emotion/styled"
import { colors, animationDuration, breakpoints, dimensions } from "../styles"

export const PopupWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-y: hidden;
  justify-content: center;
  width: 100%;
  height: 100%;
  visibility: hidden;
  position: fixed;
  opacity: 0%;
  z-index: 40;
  transition-duration: 0.2s;
  &.modal-open {
    z-index: 40;
    transition-duration: 0.2s;
    visibility: visible;
    opacity: 100%;
    background: #000000e3;
  }
`

export const PopupContainer = styled.div`
  background: white;
  position: relative;
  border-radius: 20px;
  padding: 36px 40px;
  padding-bottom: 40px;
  @media (max-width: ${breakpoints.phablet}px) {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
 
`

export const ButtonClose = styled.button`
  position: absolute;
  top: 10px;
  right: 12px;
  display: flex;
  outline: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  background: none;
`

export const HeadingContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
`

export const HeadingTitle = styled.p`
  font-weight: 400;
  font-size: 1.3rem;
  margin: 0;
  margin-bottom: 6px;
`

export const HeadingSubtitle = styled.p`
  font-weight: 300;
  font-size: 1rem;
  margin: 0;
  font-family: Lato;
`

export const Divider = styled.div`
  height: 2px;
  margin-top: 25px;
  width: 100%;
  background: #00000008;
  box-shadow: 0 0 9px 0px #0000000d;
`

export const MainContainer = styled.div`
  
`

export const FormContainerFlex = styled.div`
  width: 100%;
  display: flex;
  font-family: Lato;
  margin-bottom: 15px;
   @media (max-width: ${breakpoints.phablet}px) {
    flex-direction: column;
  };
`

export const FormContainerResponsive = styled.div`
  width: 100%;
  display: flex;
  font-family: Lato;
  flex-direction: row;
  @media (max-width: ${breakpoints.phablet}px) {
    flex-direction: row;
  };
`

export const InputWrapper = styled.div`
  display: flex;
  font-family: Lato;
  flex-direction: column;
  width: 100%;
`

export const InputWrapperMargin = styled.div`
  display: flex;
  font-family: Lato;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  @media (max-width: ${breakpoints.phablet}px) {
    margin: 0 !important;
  };
`

export const InputWrapperCol = styled.div`
  display: flex;
  font-family: Lato;
  width: 100%;
  @media (max-width: ${breakpoints.phablet}px) {
    flex-direction: column;
  };
`

export const FormSelect = styled.select`
  display: flex;
  font-family: Lato;
  font-size: 1rem;
  color: #383838;
  outline: none;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  padding: 10px 0.5em;
  cursor: pointer;
  min-height: 50px;
  line-height: 1.1;
  border: 1px solid rgb(56 56 56 / 0.08);
  background-color: #fff;
  box-shadow: 0 0 5px 0px #0000000d;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  &:focus {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9, 0 0 0 3px rgba(16, 112, 202, 0.14);
  };
  &.select-error {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #ec4c47a3, 0 0 0 3px #ec4c4714;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    min-height: 0px;
  }
`

export const FormInput = styled.input`
  display: flex;
  font-family: Lato;
  font-size: 1rem;
  width: ${props => props.isMobile && "100%"};
  color: #383838;
  outline: none;
  flex-direction: column;
  min-height: 50px;
  border-radius: 5px;
  padding: 0 20px;
  cursor: pointer;
  line-height: 1.1;
  border: 1px solid rgb(56 56 56 / 0.08);
  background-color: #fff;
  box-shadow: 0 0 5px 0px #0000000d;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  &:focus {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9, 0 0 0 3px rgba(16, 112, 202, 0.14);
  };
  &.input-error {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #ec4c47a3, 0 0 0 3px #ec4c4714;
  }
`

export const MessageInput = styled.textarea`
  display: flex;
  box-shadow: 0 0 5px 0px #0000000d;
  font-family: Lato;
  font-size: 1rem;
  color: #383838;
  outline: none;
  flex-direction: column;
  min-height: 45px;
  border-radius: 5px;
  padding: 10px 0.5em;
  cursor: pointer;
  line-height: 1.1;
  border: 1px solid rgb(56 56 56 / 0.08);
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  &:focus {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9, 0 0 0 3px rgba(16, 112, 202, 0.14);
  };
  &.input-error {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #ec4c47a3, 0 0 0 3px #ec4c4714;
  }
`

export const DatePicker = styled.input`
  height: 45px;
  color: #383838;
  font-size: 1rem;
  box-shadow: 0 0 5px 0px #0000000d;
  border: 1px solid rgb(56 56 56 / 0.08);
  border-radius: 5px;
 
  padding: 0 10px;
  font-family: Lato;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  &:focus {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9, 0 0 0 3px rgba(16, 112, 202, 0.14);
  };
  &.input-error {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #ec4c47a3, 0 0 0 3px #ec4c4714;
  };
  @media (max-width: ${breakpoints.phablet}px) {
    width: 100%;
  };
`

export const ButtonPrev = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 15px;
  font-size: 1.1rem;
`

export const BtnPrevTitle = styled.span`
  margin-left: 10px;
  font-size: 1rem;
  margin-right: 20px;
  @media (max-width: ${breakpoints.phablet}px) {
    margin-right: 0px;
  };
`

export const ButtonSubmit = styled.button`
  display: flex;
  visibility: hidden;
  opacity: 0;
  animation-duration: 0.8s;
  font-family: Lato;
  float: right;
  cursor: pointer;
  border: 0;
  padding: 15px 15px;
  background: black;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0;
  outline: none;
  font-size: 1.1rem;
  transition-duration: 0.8s;
  box-shadow: 0px 9px 8px -9px black;
  &:hover{
    transition-duration: 0.2s;
    background: ${colors.beige};
    box-shadow: 0px 9px 8px -9px ${colors.beige};
  };
  &.visible {
    animation-duration: 0.8s;
    visibility: visible;
    opacity: 1;
  };
  &.full {
    @media (max-width: ${breakpoints.phablet}px) {
      width: 100%;
    };
  }
`

export const WrapperBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: Lato;
  @media (max-width: ${breakpoints.phablet}px) {
    flex-direction: column;
  };
`

export const WrapperBottomAction = styled.div`
  width: 100%;
  display: flex;
  margin-top: 35px;
  justify-content: flex-end;
  font-family: Lato;
`
