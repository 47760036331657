import styled from "@emotion/styled"
import { colors, animationDuration, breakpoints, dimensions } from "../styles"
import { Link } from "gatsby"
import { keyframes } from "@emotion/core"

export const CaptchaWrapper = styled.div`
  visibility: hidden;
  max-width: 80%;
  animation-duration: 0.8s;
  opacity: 0;
  &.visible {
    animation-duration: 0.8s;
    visibility: visible;
    opacity: 1;
  }
`
const slideRight = keyframes`
  from {
   left: 0px;
   top: 0px;
  }

  to {
    left: 200px;
    top: 0px;
  }
`;

export const LogoWrapper = styled.div`
  background: black;
  padding: 10px;
  cursor: move;
  top: 0;
  left: 0;
  z-index: 10;
  transition-duration: 0.3s;
  width: 43px;
  height: 43px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  z-index: 10;
  &:hover {
    animation: ${slideRight} 4s linear;
  }
`



export const WrapperSlide = styled.div`
  background: red;
  z-index: 9;
  position: relative;
  width: 100%;
  height: 50px;
`

export const InputWrapper = styled.div`
  display: flex;
  font-family: Lato;
  flex-direction: column;
  width: 100%;
  position: relative;
`

export const MyDiv = styled.div`
  position: absolute;
  z-index: 9;
  top: 50px;
`

export const MyDivHeader = styled.div`
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #2196F3;
  color: #fff;
`
