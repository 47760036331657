import styled from "@emotion/styled"
import { colors, animationDuration, breakpoints, dimensions } from "../../styles"
import { Link } from "gatsby"


export const MainContainer = styled.div`
  width: 100%;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  max-width: 800px;
  margin: auto;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 7px #00000008;
`

export const Divider = styled.div`
  height: 3px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 5px;
  width: 30px;
  background: ${colors.redWaskoll};
`

export const WrapperForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  @media (min-width: ${breakpoints.phablet}px) {
    flex-direction: row;
  }
`

export const FieldWrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`

export const ColumnFieldMarginR = styled.div`
  margin-right: 15px;
`

export const Label = styled.p`
  font-size: 0.9rem;
  margin-bottom: 8px;
  color: #383838;
  margin-right: 20px;
`

export const DatePicker = styled.input`
  height: 42px;
  color: #383838;
  border: none;
  border-radius: 3px;
  padding: 0 10px;
  font-family: Lato;
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.3), inset 0 1px 2px rgba(67, 90, 111, 0.14);
`

export const ButtonSubmit = styled.button`
  display: flex;
  font-family: Lato;
  cursor: pointer;
  border: 0;
  padding: 15px 15px;
  background: ${colors.softBeige};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  color: ${colors.darkWaskoll};
  margin: 0;
  margin-top: 20px;
  outline: none;
  font-size: 1rem;
  transition-duration: 0.2s;
  &:hover{
    background: ${colors.beige};
    color: black;
  }
`

export const PasswordInput = styled.input`
  height: 42px;
  color: #383838;
  border: none;
  border-radius: 3px;
  padding: 0 10px;
  font-family: Lato;
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.3), inset 0 1px 2px rgba(67, 90, 111, 0.14);
`

export const FieldContainerFull = styled.div`
  width: 100%;
`

export const WrapperPassword = styled.div`
  position: relative;
`
export const IconPassword = styled.button`
  position: absolute;
  margin: 0px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  height: 42px;
  display: flex;
  align-items: center;
  right: 0;
  width: 35px;
`
