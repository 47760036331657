import { Card, Pane } from "evergreen-ui"
import React, { useEffect } from "react"
import { WrapperSideCart, HeaderCart, Overlay } from "./StyledSideCart"
import {createProductLink} from "../../utils/productHelpers"
import { AiOutlineDelete } from "react-icons/ai"
import useCart from "../../hooks/useCart"
import CartItem from "../SideCart/ItemCart"
import { ButtonClose, TitleBtn, TitleCartMobile, ViewButton, DeleteButton } from "../SideCartMobile/StyledSideCartMobile"
import { navigate } from "gatsby"
import { AiOutlineFileSearch } from "react-icons/ai"
import { IoMdClose } from "react-icons/io"

export default({isOpen, closeCart}) => {
  const {listCartItems, fetchCookieCart, editProductCart, deleteProductCart} = useCart()

  useEffect(() => {
    if (listCartItems.length < 1) {
      fetchCookieCart()
    }
  }, [listCartItems])

  const handleSetSize = (itemID, value) => {
    editProductCart(listCartItems, itemID, value)
  }

  const handleDeleteItem = (e, item) => {
    e.preventDefault()
    deleteProductCart(item.id)
  }

  const handleViewItem = (e, item) => {
    e.preventDefault()
    closeCart(e)
    let productUrl = createProductLink(item)
    navigate(`/${productUrl}`)
  }

  return (
    <>
      <WrapperSideCart className={isOpen ? "openCart" : "closeCart"}>
        <HeaderCart>
          <ButtonClose onClick={closeCart}>
            <IoMdClose size={28}/>
          </ButtonClose>
          <TitleCartMobile>Aperçu de votre <span style={{fontWeight: 400}}>panier</span></TitleCartMobile>
        </HeaderCart>
        <Pane flex="1" background="tint1" padding={16}>
          {listCartItems.length > 0 ? listCartItems.map((item, id) =>
            <Card
              key={item.id + "-Card" + id}
              backgroundColor="white"
              elevation={0}
              padding={15}
              overflow={"hidden"}
              marginBottom={15}
              display="flex"
              position="relative"
              alignItems="center"
              justifyContent="center"
            >
              <DeleteButton onClick={(e) => handleDeleteItem(e, item)}>
                <AiOutlineDelete size={16} />
                <TitleBtn>Supprimer du panier</TitleBtn>
              </DeleteButton>
              <ViewButton onClick={(e) => handleViewItem(e, item)}>
                <AiOutlineFileSearch size={16} />
                <TitleBtn>Voir le bijoux</TitleBtn>
              </ViewButton>
              <CartItem setSize={handleSetSize} key={item.id} productSrc={item}/>
            </Card>
          ) : <h3 style={{textAlign: "center"}}>Votre panier est vide</h3>}

        </Pane>
      </WrapperSideCart>
      <Overlay onClick={closeCart} className={isOpen ? "openCart" : "closeCart"}/>
    </>
  )
}
