import styled from "@emotion/styled"
import { breakpoints, colors, megaMenuEntry, megaMenuOut } from "../../styles"

export const SizeItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  background: ${props => props.selected ? colors.softBeige : "#fafafa"};
  color: ${props => props.selected ? colors.darkWaskoll : "#808080"};
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 0;
  padding-left: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    background: ${colors.softBeige};
    color: ${colors.darkWaskoll};
  }
`

export const SizeValue = styled.p`
  font-family: Lato;
`

export const SizeProductBlock = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-item: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  visibility: hidden;
  &.close {
    animation: ${megaMenuOut} 0.75s ease forwards;
  };
  &.open {
    visibility: visible;
    animation: ${megaMenuEntry} 0.75s ease forwards;
  }
  
  @media (max-width: ${breakpoints.tablet}px) {
    justify-content: center;
    align-items: center;
    margin: auto;
  }
`
