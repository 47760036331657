import React from "react"
import {
  FooterServicesContainer,
  FooterServicesWrapper,
  ServicesItem,
  ServicesItemSubTitle, ServicesItemTitle
} from "./StyledFooterServices"

export default ({toggleModal}) => {

  return (
    <FooterServicesContainer>
      <FooterServicesWrapper>
        <ServicesItem>
          <i className="icon-call"/>
          <ServicesItemTitle>Contact</ServicesItemTitle>
          <ServicesItemSubTitle>Nous sommes à votre disposition pour vous renseigner.</ServicesItemSubTitle>
        </ServicesItem>
        <ServicesItem>
          <i className="icon-bell"/>
          <ServicesItemTitle>Services</ServicesItemTitle>
          <ServicesItemSubTitle>
            Découvrez les conseils et services de la Maison.
          </ServicesItemSubTitle>
        </ServicesItem>
        <ServicesItem onClick={toggleModal}>
          <i className="icon-open-book"/>
          <ServicesItemTitle>Rendez-vous</ServicesItemTitle>
          <ServicesItemSubTitle>
            Prendre un rendez-vous directement en ligne.
          </ServicesItemSubTitle>
        </ServicesItem>
      </FooterServicesWrapper>
    </FooterServicesContainer>
  )
}
