import { API, graphqlOperation } from "@aws-amplify/api"
import {
  createProduct,
  editProduct,
  deleteProduct,
  updateUser,
  updateDataSite,
  deleteDataSite
} from "../graphql/mutations"
import {
  getAllProductsQuery,
  getUserQuery,
  getAllSlidesQuery,
  getAllCollectionsQuery,
  getAllMegaMenuQuery,
  queryGetAllFeaturedCollection
} from "../graphql/queries"

export const addDynamoDBProduct = async (data) => {
  await API.graphql(graphqlOperation(createProduct, { input: data }))
}

export const editDynamoDBProduct = async (data) => {
  await API.graphql(graphqlOperation(editProduct, { input: data }))
}

export const updateDynamoDBSlider = async (data) => {
  await API.graphql(graphqlOperation(updateDataSite, { input: data }))
}

export const updateDynamoDBCollections = async (data) => {
  await API.graphql(graphqlOperation(updateDataSite, { input: data }))
}

export const updateDynamoDBFeaturedCollection = async (data) => {
  await API.graphql(graphqlOperation(updateDataSite, { input: data }))
}

export const updateDynamoDBMegaMenu = async (data) => {
  await API.graphql(graphqlOperation(updateDataSite, { input: data }))
}

export const getAllProducts = () => {
  return API.graphql(graphqlOperation(getAllProductsQuery, {
    filter: null,
    $limit: 100,
    $nextToken: null,
  }))
}

export const getAllSlides = () => {
  return API.graphql(graphqlOperation(getAllSlidesQuery, {
    filter: null,
    $limit: 100,
    $nextToken: null,
  }))
}

export const getAllCollections = () => {
  return API.graphql(graphqlOperation(getAllCollectionsQuery, {
    filter: null,
    $limit: 100,
    $nextToken: null,
  }))
}

export const apiGetFeaturedCollection = () => {
  return API.graphql(graphqlOperation(queryGetAllFeaturedCollection, {
    filter: null,
    $limit: 100,
    $nextToken: null,
  }))
}

export const getAllMegaMenu = () => {
  return API.graphql(graphqlOperation(getAllMegaMenuQuery, {
    filter: null,
    $limit: 100,
    $nextToken: null,
  }))
}

export const getDynamoDBUser = (userID) => {
  return API.graphql(graphqlOperation(getUserQuery, {
    id: userID,
  }))
}

export const deleteDynamoDBProduct = async (data) => {
  await API.graphql(graphqlOperation(deleteProduct, { id: data.id }))
}

export const editDynamoDBUser = async (data) => {
  await API.graphql(graphqlOperation(updateUser, { input: data }))
}

export const deleteDynamoDBCollection = async (collectionId) => {
  await API.graphql(graphqlOperation(deleteDataSite, { id: collectionId }))
}