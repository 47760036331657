import { createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import userReducer from './reducers/userReducer'
import adminReducer from "./reducers/adminReducer"
import cartReducer from "./reducers/cartReducer"

const isDev = process.env.NODE_ENV === 'development'
const store = createStore(
  combineReducers({
    user: userReducer,
    admin: adminReducer,
    cart: cartReducer
  }),
  isDev ? composeWithDevTools() : undefined
)

export default store
