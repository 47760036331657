import { actionAddItemCart, actionAddCookieCart } from "../store/actions/CartActions"
import { useDispatch, useSelector } from "react-redux"

const useCart = () => {
  const dispatch = useDispatch()

  const addItemCart = item => {
    return new Promise(resolve => {
      if (typeof window !== `undefined`) {
        let currentCookie = JSON.parse(localStorage.getItem('waskollCart'))
        let newCookie = []
        console.info("CURRENT COOKIE:", currentCookie)
        if (currentCookie) {
          newCookie = currentCookie
          newCookie.push(item)
        }
        else {
          newCookie.push(item)
        }
        localStorage.setItem('waskollCart', JSON.stringify(newCookie))
        dispatch(actionAddItemCart(item))
      }
      resolve()
    })
  }

  const fetchCookieCart = () => {
    let currentCookie = JSON.parse(localStorage.getItem('waskollCart'))
    if (currentCookie && currentCookie.length > 0) {
      dispatch(actionAddCookieCart(currentCookie))
    }
  }

  const editProductCart = (listCartItems, itemID, value) => {
    let newCartList = listCartItems.map(item => {
      let newItem = item
      if (item.id === itemID) newItem.currentSize = value
      return newItem
    })
    localStorage.setItem('waskollCart', JSON.stringify(newCartList))
    dispatch(actionAddCookieCart(newCartList))
  }

  const deleteProductCart = (itemID) => {
    let newCartList = listCartItems.filter(item => item.id !== itemID)
    localStorage.setItem('waskollCart', JSON.stringify(newCartList))
    dispatch(actionAddCookieCart(newCartList))
  }

  const listCartItems = useSelector(state => state.cart.listItems)

  return {
    addItemCart,
    listCartItems,
    fetchCookieCart,
    editProductCart,
    deleteProductCart
  }
}

export default useCart
