import styled from "@emotion/styled"
import { breakpoints, colors, dimensions } from "../styles"
import { Link } from "gatsby"

export const Footer = styled.div`
  width: 100%;
  background: ${colors.darkWaskoll};
  padding-top: 30px;
  padding-bottom: 20px;
`

export const WrapperContent = styled.div`
  max-width: ${dimensions.contentWidth};
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const WrapperSocial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`
export const WrapperMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
  };
`

export const Separator = styled.div`
  width: 100%;
  background: #a76f774d;
  height: 2px;
  margin-bottom: 20px;
`

export const Copyright = styled.span`
  color: white;
  font-family: Lato;
  font-size: 0.9rem;
  font-weight: 300;
  margin-top: 10px;
`

export const MenuLink = styled(Link)`
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  font-weight: 300;
  font-size: 0.8rem;
  transition-duration: 0.3s;
  text-transform: uppercase;
  font-family: Lato;
  margin-left: 10px;
  margin-right: 10px;
  &:hover {
    background: white;
    color: ${colors.darkWaskoll};
    border-radius: 4px;
  }
`

export const SocialLink = styled.a`
  margin-left: 10px;
  margin-right: 10px;
  transition-duration: 0.5s;
  &:hover {
    transform: scale(1.3);
  }
`

export const LogoWrapper = styled(Link)`
  transition-duration: 0.3s;
  &:hover {
    transform: rotate(180deg);
  }
`


