import { addDynamoDBProduct, editDynamoDBProduct } from "./API"

const getCollectionId = (listCollections, nameCollection) => {
  let collection = listCollections.find(item => item.data.name === nameCollection)

  return collection ? collection.id : "Non Catégorisé"
}

export const createVariationsProduct = (allProducts, listCollections) => {
  const allProductsVariations = []
  allProducts.forEach(product => {
    let newProduct = { ...product, price: parseInt(product.price,10), collection: getCollectionId(listCollections, product.collection) }
    delete newProduct.preview
    newProduct.variations = []
    let variations = allProducts.filter(item => item.id !== product.id)
    if (variations) {
      variations.forEach(variation => {
        newProduct.variations.push({
          id: variation.id,
          ref: variation.ref,
          attributeMetal: variation.attributeMetal,
          attributeGem: variation.attributeGem,
          images: variation.images,
          description: variation.description,
          price: parseInt(variation.price,10),
        })
      })
    }
    allProductsVariations.push(newProduct)
  })
  return allProductsVariations
}

export const createProduct = (currentProduct, listCollections) => {
  return new Promise((resolve, reject) => {
    let newProduct = { ...currentProduct, price: parseInt(currentProduct.price,10), collection: getCollectionId(listCollections, currentProduct.collection) }
    delete newProduct.preview
    newProduct.variations = []
    addDynamoDBProduct(newProduct)
      .then(() => {
        console.info("Create Product: SUCCESS ")
        resolve()
      })
      .catch((e) => {
        console.error("Create Product: FAIL", e)
        reject()
      })
  })
}

export const createProductVariation = (listProducts, listCollections) => {
  return new Promise((resolve, reject) => {
    const allPromises = []
    let allProducts = [
      ...listProducts
    ]
    allProducts = createVariationsProduct(allProducts, listCollections)

    allProducts.forEach(product => allPromises.push(addDynamoDBProduct(product)))
    Promise.all(allPromises)
      .then(() => {
        console.info("Create Product: SUCCESS ")
        resolve()
      })
      .catch((e) => {
        console.error("Create Product: FAIL", e)
        reject()
      })
  })
}

export const editProduct = (productDatabase, currentProduct, listCollections) => {
  return new Promise((resolve, reject) => {
    const allPromises = []
    const listProducts = productDatabase.filter(product => currentProduct.variations.some(variation => variation.id === product.id));
    let allProducts = [
      ...listProducts,
      currentProduct
    ]
    allProducts = createVariationsProduct(allProducts, listCollections)

    allProducts.forEach(product => allPromises.push(editDynamoDBProduct(product)))
    Promise.all(allPromises)
      .then(() => {
        console.info("Create Product: SUCCESS ")
        resolve()
      })
      .catch((e) => {
        console.error("Create Product: FAIL", e)
        reject()
      })
  })
}

export const removeProduct = (productDatabase, currentProduct, listCollections) => {
  return new Promise((resolve, reject) => {
    const allPromises = []
    let listProducts = productDatabase.filter(product => currentProduct.variations.some(variation => variation.id === product.id));

    listProducts = createVariationsProduct(listProducts, listCollections)

    listProducts.forEach(product => allPromises.push(editDynamoDBProduct(product)))
    Promise.all(allPromises)
      .then(() => {
        console.info("Create Product: SUCCESS ")
        resolve()
      })
      .catch((e) => {
        console.error("Create Product: FAIL", e)
        reject()
      })
  })
}

function escapeRegExp(s) {
  return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
}

export const onSearch2 = (q, list, attribute_list, allProducts) => {
  let result = []
  if (allProducts.length > 0) {
    allProducts.map(item => {
      for (const [key, value] of Object.entries(item)) {
        if (attribute_list.some(attr => attr === key)) {
          if (value.toString().toLowerCase().includes(q.toString().toLowerCase())) result.push(item)
        }
      }
    })
  }
  return result
  /*if (allProducts.length > 0) {
    const words = q
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s);
    const hasTrailingSpace = q.endsWith(" ");
    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join("") + ".+",
      "gi"
    );
    return list.filter(item => {
      let result = false;
      attribute_list.forEach(attr => {
        result =
          result ||
          searchRegex.test(attr.split(".").reduce((o, i) => o[i], item));
      });
      return result;
    });
  }*/
};

export const onSearch = (q, list, attribute_list, allProducts) => {
  if (allProducts.length > 0) {
    const words = q
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s);
    const hasTrailingSpace = q.endsWith(" ");
    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join("") + ".+",
      "gi"
    );
    return list.filter(item => {
      let result = false;
      attribute_list.forEach(attr => {
        result =
          result ||
          searchRegex.test(attr.split(".").reduce((o, i) => o[i], item));
      });
      return result;
    });
  }
};

export const normalizeString = str => {
  return str
    .toLowerCase()
    .replace(/'/g, "-")
    .replace(/ /g, "-")
    .normalize('NFD').replace(/[\u0300-\u036f]/g, "")
}

export const createProductLink = (product) => {
  let url = `${product.attributeType} ${product.attributeMetal} ${product.attributeGem} ${product.collection}`;
  url = normalizeString(url)
  return url
}

export const addItemWishListCookie = (product) => {
  const currentCookie = getWishListCookie()
  let nextCookie = []
  if (currentCookie) {
    let isExist = currentCookie.find(item => item === product)
    if (isExist) console.info("COOKIE EXIST")
    else {
      nextCookie = [
        ...currentCookie,
        product
      ]
    }
  } else nextCookie.push(product)
  localStorage.setItem('wishList', JSON.stringify([nextCookie]))
}

export const removeItemWishListCookie = (product) => {
  const currentCookie = getWishListCookie()
  let nextCookie = currentCookie.filter(item => item !== product)
  if (nextCookie.length < 1) localStorage.removeItem('wishList')
  else localStorage.setItem('wishList', JSON.stringify([nextCookie]))
}

export const getWishListCookie = () => {
  const cookieWishList = localStorage.getItem('wishList')
  if (cookieWishList) {
    let currentCookie = JSON.parse(cookieWishList)
    return currentCookie[0]
  }
  else return false

}
