import styled from "@emotion/styled"
import { breakpoints, colors, dimensions } from "../styles"
import { Link } from "gatsby"

export const FooterServicesContainer = styled.div`
  width: 100%;
  padding: 30px;
  margin-bottom: 50px;
`

export const FooterServicesWrapper = styled.div`
  max-width: ${dimensions.contentWidth};
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${breakpoints.phablet}px) {
    flex-direction: column;
  }
`

export const ServicesItem = styled.div`
  max-width: 355px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 40px;
  background: white;
  font-size: 2.5rem;
  color: #5b5b5b;
  text-align: center;
  transition-duration: 0.3s;
  cursor: pointer;
  border-radius: .5rem;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 20px 0px rgba(0,0,0,.06);
    z-index: 8;
  };
  @media (max-width: ${breakpoints.hdesktop}px) {
    max-width: 29vw;
    padding: 20px 25px;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    max-width: 29vw;
    padding: 20px 15px;
  }
  @media (max-width: ${breakpoints.phablet}px) {
    margin-bottom: 20px;
    padding: 20px 25px;
    max-width: 355px;
  }
`

export const ServicesItemTitle = styled.span`
  color: black;
  font-family: Lora;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 15px;
`

export const ServicesItemSubTitle = styled.span`
  color: #5b5b5b;
  font-family: Lora;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 10px;
`


