const INITIAL_STATE = { listItems: [] }

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_ITEM_CART':
      return {
        ...state,
        listItems: [
          ...state.listItems,
          action.item
        ]
      }
    case 'ADD_COOKIE_CART':
      return {
        ...state,
        listItems: action.listProducts
      }
    default:
      return state
  }
}
