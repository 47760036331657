const INITIAL_STATE = { isLoading: false, isSuccess: false, listProducts: [] }

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PRODUCT_VARIATION_ADMIN':
      return {
        ...state,
        listProducts: [
          ...state.listProducts,
          action.product
        ],
        isSuccess: true
      }
    case 'DELETE_PRODUCT_VARIATION_ADMIN':
      return {
        ...state,
        listProducts: [
          ...action.listProducts,
        ]
      }
    case 'SUCCESS_SEND_PRODUCT_ADMIN':
      return {
        ...state,
        listProducts: [],
        isLoading: false,
        isSuccess: true,
      }
    case 'CLEAR_LIST_PRODUCT_ADMIN':
      return {
        ...state,
        listProducts: []
      }
    case 'CLEAR_SUCCESS_ADMIN':
      return {
        ...state,
        isSuccess: false
      }
    default:
      return state
  }
}
