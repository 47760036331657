export const createProduct = /* GraphQL */ `
  mutation createProduct(
    $input: CreateProductInput!
  ) {
    createProduct(input: $input) {
      attributeType
      attributeMetal
      attributeGem
      description
      images {
        id
      }
      plural
      genre
      priceStartFrom
      variations {
        id
        attributeMetal
        attributeGem
        description
        images {
          id
        }
      }
    }
  }
`

export const editProduct = /* GraphQL */ `
  mutation editProduct(
    $input: CreateProductInput!
  ) {
    editProduct(input: $input) {
      id
      attributeType
      attributeMetal
      attributeGem
      description
      price
      ref
      singleGem
      images {
        id
      }
      plural
      genre
      priceStartFrom
      variations {
        attributeMetal
        attributeGem
        description
        images {
          id
        }
        plural
        genre
        priceStartFrom
      }
    }
  }
`

export const deleteProduct = `
  mutation DeleteProduct(
    $id: ID!
  ) {
    deleteProduct(id: $id) {
      id
    }
  }
`

export const deleteDataSite = `
  mutation deleteDataSite(
    $id: ID!
  ) {
    deleteDataSite(id: $id) {
      id
    }
  }
`

export const updateUser = /* GraphQL */ `
  mutation updateUser(
    $input: UpdateUserInput!
  ) {
    updateUser(input: $input) {
      id
    }
  }
`

export const updateDataSite = /* GraphQL */ `
  mutation editDataSite(
    $input: DataSiteInput!
  ) {
    editDataSite(input: $input) {
      id
    }
  }
`
