import React, { useEffect, useState } from "react"

import {
  Divider,
  HeadingContainer,
  HeadingSubtitle,
  HeadingTitle,
  PopupContainer,
  PopupWrapper,
  MainContainer,
  FormContainerFlex,
  InputWrapper,
  ButtonClose,
  FormSelect,
  FormInput,
  DatePicker,
  MessageInput,
  ButtonSubmit,
  WrapperBottomAction,
  InputWrapperCol,
  FormContainerResponsive,
  InputWrapperMargin, ButtonPrev, WrapperBtn, BtnPrevTitle
} from "./StyledPopup"
import { FaTimes, FaCheck, FaArrowRight, FaArrowLeft } from "react-icons/fa"
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi"
import { Label } from "../Account/Profile/StyledAccountProfile"
import { attributeProfileGender } from "../shared/AccountMetaData"
import { attributeBookSubject } from "../shared/FormMetadata"
import { sendEmailToLambda } from "../../utils/userHelpers"
import Captcha from "../Captcha"

export default React.memo(({isOpen, closeModal, modalType, data}) => {
  console.log("POPUP RENDER")
  const [currentStep, setCurrentStep] = useState(0)
  const [currentProfile, setCurrentProfile] = useState({
    gender: "",
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    dateBook: "",
    subject: "",
    message: "",
  })
  const [isError, setError] = useState({})
  const [isValidCaptcha, setValidCaptcha] = useState(false)

  useEffect(() => {
    return() => {
      setValidCaptcha(false)
    }
  }, [])

  const handleChange = (name, value) => {
    setCurrentProfile({
      ...currentProfile,
      [name]: value
    })
    if (isError[name]) setError({...isError, [name]: false})
  }

  const handleIsValidCaptcha = () => {
    setValidCaptcha(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    /*sendEmailToLambda()
      .then(res => console.log(res))
      .catch(err => console.log(err))*/   /* let emptyField = {};
    Object.keys(currentProfile).forEach((key) => {
      if (currentProfile[key] === "" && key !== "message") {
        emptyField = {
          ...emptyField,
          [key]: true,
        }
      }
    });
    if (emptyField) setError( {...emptyField})
    else {
      sendEmailToLambda()
        .then( () => {})
        .catch(() => {})
    }*/
   /* editUserProfile(currentProfile)
      .then(() => {
        successCallback()
      })
      .catch((err) => {
        console.log("EDIT PROFILE: ERROR ", err)
      })*/
  }

  const handleStepChange = (e, step) => {
    e.preventDefault()
    if (step === "next") {
      setCurrentStep(currentStep + 1)
    }
    if (step === "prev") {
      setCurrentStep(currentStep - 1)
    }
  }

  const createForm = () => {
    return (<div>
      {currentStep === 0 && (
        <div>
          <FormContainerFlex>
            <FormContainerResponsive>
              <InputWrapper style={{width: "10ch", marginRight: "20px"}}>
                <Label>Titre</Label>
                <FormSelect
                  value={currentProfile.gender}
                  className={!!isError.gender && "select-error"}
                  onChange={e => handleChange("gender", e.target.value)}>
                  {attributeProfileGender().map((item, index) => (
                    <option key={item + index} value={item}>{item}</option>
                  ))}
                </FormSelect>
              </InputWrapper>
              <InputWrapperMargin>
                <Label>Nom</Label>
                <FormInput
                  className={!!isError.lastName && "input-error"}
                  value={currentProfile.lastName}
                  onChange={e => handleChange("lastName", e.target.value)}
                />
              </InputWrapperMargin>
            </FormContainerResponsive>

            <InputWrapper>
              <Label>Prénom</Label>
              <FormInput
                className={!!isError.firstName && "input-error"}
                value={currentProfile.firstName}
                onChange={e => handleChange("firstName", e.target.value)}
              />
            </InputWrapper>
          </FormContainerFlex>
          <InputWrapperCol>
            <InputWrapperMargin>
              <Label>Téléphone</Label>
              <FormInput
                type={"phone"}
                className={!!isError.phone && "input-error"}
                value={currentProfile.phone}
                onChange={e => handleChange("phone", e.target.value)}
              />
            </InputWrapperMargin>
            <InputWrapper>
              <Label>Email</Label>
              <FormInput
                type={"email"}
                className={!!isError.email && "input-error"}
                value={currentProfile.email}
                onChange={e => handleChange("email", e.target.value)}
              />
            </InputWrapper>
          </InputWrapperCol>
        </div>
      )}

      {currentStep === 1 && (
        <div>
          <InputWrapper>
            <Label>Date souhaitée</Label>
            <DatePicker
              type="date"
              name="dateBook"
              className={!!isError.dateBook && "input-error"}
              value={currentProfile.dateBook}
              onChange={e => handleChange("dateBook", e.target.value)}
            />
          </InputWrapper>
          <InputWrapper style={{marginBottom: "15px"}}>
            <Label>Objet du rendez-vous</Label>
            <FormSelect
              value={currentProfile.subject}
              className={!!isError.subject && "select-error"}
              onChange={e => handleChange("subject", e.target.value)}>
              {attributeBookSubject().map((item, index) => (
                <option key={item + index} value={item}>{item}</option>
              ))}
            </FormSelect>
          </InputWrapper>
          <InputWrapper>
            <Label>Message</Label>
            <MessageInput
              type={"text"}
              className={!!isError.message && "textarea-error"}
              value={currentProfile.message}
              onChange={e => handleChange("message", e.target.value)}
            />
          </InputWrapper>
        </div>
      )}



    </div>)
  }

  return (
    <PopupWrapper className={isOpen && "modal-open"}>
      <PopupContainer>
        <ButtonClose onClick={closeModal}>
          <FaTimes size={24}/>
        </ButtonClose>
        {data.title && (
          <HeadingContainer>
            <HeadingTitle>{data.title}</HeadingTitle>
            {data.subTitle && (
              <HeadingSubtitle>{data.subTitle}</HeadingSubtitle>
            )}
            <Divider/>
          </HeadingContainer>
        )}

        {modalType === "rendez-vous" && createForm()}

        <WrapperBottomAction>
          {currentStep === 0 && (
            <ButtonSubmit className={"visible"} onClick={(e) => handleStepChange(e, "next")}>
              <span style={{marginRight: "10px"}}>Suivant</span>
              <FaArrowRight size={16} color={"white"}/>
            </ButtonSubmit>
          )}
          {currentStep === 1 && (
            <WrapperBtn>
              <ButtonPrev onClick={(e) => handleStepChange(e, "prev")}>
                <HiOutlineArrowNarrowLeft size={18} color={"black"}/>
                <BtnPrevTitle>Précédent</BtnPrevTitle>
              </ButtonPrev>
              <ButtonSubmit className={"visible full"} onClick={(e) => handleSubmit(e)}>
                <FaCheck color={"white"}/>
                <span style={{marginLeft: "10px"}}>{data.btnTitle}</span>
              </ButtonSubmit>
            </WrapperBtn>
          )}
          {/*<Captcha className={!isValidCaptcha ? "visible" : ""} isValid={handleIsValidCaptcha}/>
          <ButtonSubmit className={isValidCaptcha ? "visible" : ""} onClick={(e) => handleSubmit(e)}>
            <FaCheck color={"white"}/>
            <span style={{marginLeft: "10px"}}>{data.btnTitle}</span>
          </ButtonSubmit>*/}

          {/*{isMobile && (
              <ButtonSubmit className={"visible full"} onClick={(e) => handleSubmit(e)}>
                <FaCheck color={"white"}/>
                <span style={{marginLeft: "10px"}}>{data.btnTitle}</span>
              </ButtonSubmit>
            )}*/}

        </WrapperBottomAction>

      </PopupContainer>
    </PopupWrapper>
  )
})
