/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserQuery = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      street_1
      street_2
      gender
      company
      city
      zip
      country
      phone
      email
      role
      birthday
      wishList {
        productId
      }
      orderList {
        id
        userId
        ref
        date
        status
        amount
        productList {
          id
          collection
          attributeType
          attributeMetal
          attributeGem
          attributeSize
          images {
            id 
          }
          price
        }
      }
    }
  }
`
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        street_1
        street_2
        gender
        company
        city
        zip
        country
        phone
        email
        role
        birthday
      }
      nextToken
    }
  }
`

export const getAllProductsQuery = /* GraphQL */ `
  query listProducts{
    listProducts {
      items {
        id
        collection
        attributeType
        attributeMetal
        attributeGem
        attributeSize
        description
        ref
        price
        images {
          id 
        }
        plural
        genre
        priceStartFrom
        singleGem
        variations {
          attributeMetal
          attributeGem
          description
          price
          id
          ref
          plural
          genre
          priceStartFrom
          images {
            id
          }
        }
      }
    }
  }
`;

export const getAllSlidesQuery = /* GraphQL */ `
  query listDataSite {
  listDataSite(filter: {type: {eq: "slider"}}) {
    items {
      data
      id
      type
    }
  }
}
`;

export const getAllCollectionsQuery = /* GraphQL */ `
  query listDataSite {
  listDataSite(filter: {type: {eq: "collection"}}) {
    items {
      data
      id
      type
    }
  }
}
`;

export const queryGetAllFeaturedCollection = /* GraphQL */ `
  query listDataSite {
  listDataSite(filter: {type: {eq: "home"}}) {
    items {
      data
      id
      type
    }
  }
}
`;

export const getAllMegaMenuQuery = /* GraphQL */ `
  query listDataSite {
  listDataSite(filter: {type: {eq: "megaMenu"}}) {
    items {
      data
      id
      type
    }
  }
}
`;