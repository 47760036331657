import styled from "@emotion/styled"
import { colors } from "../../styles"
import { Link } from "gatsby"

const MetaContainer = styled.div`
  flex: 1;
  padding-top: 230px;
  border-radius: 20px;
`

const VariationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const TitleCollection = styled.h1`
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 5px;
`

const TitleProduct = styled.h2`
  font-size: 1rem;
  color: ${colors.darkWaskoll};
  font-weight: 300;
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
`

const DescriptionProduct = styled.p`
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
  color: ${colors.darkGrey};
  margin: 0 20px;
`

const SeparationTitle = styled.div`
  height: 3px;
  width: 40px;
  background: #932C2D;
  margin-top: 5px;
  margin-bottom: 20px;
`

const ButtonSetVariation = styled.button`
  width: 100%;
  background: none;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid #f3f3f3;
`

const ButtonGemVariationTitle = styled.p`
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 10px;
`

const BlockSize = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const TitleSizeGuide = styled.button`
  border: none;
  background: none;
  padding: none;
  cursor: pointer;
  outline: none;
  font-style: italic;
  font-size: 12px;
  text-decoration: underline;
`

const Price = styled.span`
  font-size: 1.3rem;
  letter-spacing: 1px;
`

export const PriceEyeIcon = styled.span`
  position: absolute;
  top: 32px;
`

const PriceBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const HideShowPrice = styled.button`
  border: none;
  background: none;
  padding: none;
  cursor: pointer;
  outline: none;
  font-size: 12px;
`

const HideShowPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`

export const AddToCartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding-top: 20px;
  padding-bottom: 50px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background: white;
  box-shadow: 0 0px 12px 0px #0000000d;
`

export const ExtraActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  padding: 20px 30px;
  text-align: center;
  color: #292929;
`

export const ExtraActionList = styled.div`
  display: flex;
  justify-content: space-around;
  color: #292929;
  margin-top: 40px;
`

export const ExtraActionItem = styled(Link)`
  display: flex;
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #292929;
  font-family: Cormorant;
  font-size: 1.2rem;
  font-weight: 400;
  flex: 1;
  text-align: center;
`

export const Divider = styled.div`
   width: 1px;
   border-radius: 100%;
   background: #f1f1f1;
`

export const ExtraActionItemTitle = styled.span`
  margin-top: 15px;
`

export const ExtraActionHeading = styled.span`
  font-family: Cormorant;
  font-style: italic;
  font-size: 1.3rem;
`

const AddToCart = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 12px;
  background: ${props => props.basket ? "black" : "#f7f7f7"};
  color: ${props => props.basket ? "white" : "#4D1720"};;
  padding: 16px 20px;
  margin: 0 20px;
  border-radius: 20px;
  transition-duration: 0.3s;
  &.selected {
    transition-duration: 0.3s;
    background: black;
    color: white;
  }
  `

const TitleButton = styled.span`
  font-size: 1.3rem;
  letter-spacing: 1px;
  margin-left: 15px;
  font-family: Cormorant;
  font-weight: 600;
`

const BtnOutline = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid #932C2D;
  color: #932C2D;
  cursor: pointer;
  outline: none;
  font-size: 12px;
  background: white;
/*  font-family: Muli, Sans-serif;*/
  padding: 9px 12px 9px 12px;
  border-radius: 5px;
`

export {
  MetaContainer,
  TitleCollection,
  TitleProduct,
  DescriptionProduct,
  SeparationTitle,
  ButtonSetVariation,
  ButtonGemVariationTitle,
  BlockSize,
  TitleSizeGuide,
  Price,
  HideShowPrice,
  PriceBlock,
  AddToCart,
  TitleButton,
  BtnOutline,
  VariationContainer,
  HideShowPriceWrapper
}
