// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-single-product-index-js": () => import("./../src/components/SingleProduct/index.js" /* webpackChunkName: "component---src-components-single-product-index-js" */),
  "component---src-pages-account-index-js": () => import("./../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-confirmation-js": () => import("./../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-dashboard-js": () => import("./../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-history-js": () => import("./../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-wishlist-js": () => import("./../src/pages/wishlist.js" /* webpackChunkName: "component---src-pages-wishlist-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-main-js": () => import("./../src/templates/main.js" /* webpackChunkName: "component---src-templates-main-js" */)
}

