import { editDynamoDBUser } from "./API"
import { Auth } from 'aws-amplify'

export const editUserProfile = (currentProfile) => {
  return new Promise((resolve, reject) => {
    Object.keys(currentProfile).forEach((key) => (currentProfile[key] === "") && delete currentProfile[key]);
    if (currentProfile.phone) currentProfile.phone = parseInt(currentProfile.phone, 10)
    editDynamoDBUser(currentProfile)
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const editUserEmail = async newProfile => {
  const user = await Auth.currentAuthenticatedUser();
  return new Promise((resolve, reject) => {
    Auth.updateUserAttributes(user, { email: newProfile.email })
      .then(() => {
        editDynamoDBUser(newProfile)
          .then(() => {
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const editUserPassword = async (data) => {
  const user = await Auth.currentAuthenticatedUser();
  return new Promise((resolve, reject) => {
    Auth.changePassword(user, data.oldPassword, data.newPassword)
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const sendForgotPasswordCode = async (email) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(email)
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const sendNewPassword = async (email, code, password) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(
      email,
      code,
      password
    )
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const sendEmailToLambda = async (email, code, password) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers({
      'Content-Type': 'text/plain',
      'Origin': 'http://localhost:8000/'
    });

    const url = "https://d573i3j4o3.execute-api.eu-central-1.amazonaws.com/dev/sendEmail";
    fetch(url, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        email: "test",
      }),
    }).then(res => resolve(res)).catch(err => reject(err))
  })
}


