import React, { useEffect, useState } from "react"
import MegaMenu from "../MegaMenu"
import { Container, NavLink, NavMenu, SelectedItemBar } from "./StyledNavMenu"

export default ({isSticky, listMenu}) => {
  const [isOpen, setOpen] = useState(false)
  const [currentMenu, setCurrentMenu] = useState(false)

  useEffect(() => {
    if (currentMenu && !isOpen) setCurrentMenu(false)
  }, [isOpen])

  const handleClick = (e, selected) => {
    e.preventDefault()
    if (selected !== "exception") {
      if (selected === currentMenu) setOpen(false)
      else setOpen(true)
      setCurrentMenu(selected)
    }
  }

  const handleMouseLeave = e => {
    if (e) e.preventDefault()
    if (isOpen) {
      setOpen(false)
    }
  }

  return (
    <Container isOpen={isOpen} isSticky={isSticky} onPointerLeave={e => handleMouseLeave(e)}>
      <NavMenu>
        {listMenu.map(itemMenu => (
          <div key={itemMenu.slug}>
            <NavLink className={currentMenu && currentMenu !== itemMenu.slug ? "opacity" : null} onClick={e => handleClick(e, itemMenu.slug)}>{itemMenu.title}</NavLink>
            <SelectedItemBar className={currentMenu === itemMenu.slug ? "active" : null}/>
          </div>
        ))}
      </NavMenu>

      <MegaMenu
        closeMenu={handleMouseLeave}
        listMenu={listMenu}
        currentMenu={currentMenu}
        isOpen={isOpen}
      />
    </Container>
  )
}
