import React from 'react'
import styled from "@emotion/styled"

export const BlockItemCart = styled.div`
  width: 100%;
  padding-bottom: 25px;
  padding-top: 25px;
`

export const BlockMetaProduct = styled.div`
  width: 100%;
`

export const TitleProductCart = styled.h2`
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
  margin-bottom: 12px;
`

export const TitleSize = styled.h2`
  font-size: 14px;
  font-weight: 300;
  margin: 0;
`

export const ToggleEditSize = styled.button`
  border: none;
  background: none;
  margin-left: 10px;
  padding: none;
  cursor: pointer;
  outline: none;
  font-style: italic;
  font-size: 12px;
  text-decoration: underline;
`

export const DescriptionProductCart = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #808080;
  margin: 0;
`
