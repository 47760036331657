import React, { useState } from "react"
import { navigate } from "gatsby"
import { FaBook, FaUserLock } from "react-icons/fa"
import {MENU_MOBILE_LIST} from "../shared/MenuMetadata"

import {
  MegaMenuListWrapper,
  MegaMenuMobileContainer,
  MenuItemTitle,
  MenuItemWrapper,
  WishListTitle,
  MenuItemSubWrapper,
  MenuItemSubTitle,
  MenuHeadingWrapper,
  ExtraActionList, WishListWrapper,
  ExtraActionItemBtn,
  BottomWrapper,
  Divider, DividerV
} from "./StyledMegaMenuMobile"
import { AiOutlineDown, AiOutlineRight, AiOutlineHeart } from "react-icons/ai"
import {
  ExtraActionItemTitle,
} from "../SingleProductMobile/MetaProduct/StyledMetaProduct"
import useAuth from "../../hooks/useAuth"
import { normalizeString } from "../../utils/productHelpers"


export default ({ isOpen, togglePopup, closeMenu }) => {
  const [isOpenSubMenu, setOpenSubMenu] = useState([])
  const { currentUser } = useAuth()

  const handleOpenSubMenu = (e, id) => {
    e.preventDefault(e)
    let idMenuItem = id.toString()
    if (isOpenSubMenu.find(itemMenu => itemMenu === idMenuItem)) {
      setOpenSubMenu(isOpenSubMenu.filter(itemMenu => itemMenu !== idMenuItem))
    } else setOpenSubMenu([...isOpenSubMenu, idMenuItem])
  }

  const handleNavigateHistory = (e, name) => {
    closeMenu(e)
    navigate(`/history#${name}`)
  }

  const handleNavigate = (e, slug, name) => {
    closeMenu(e)
    let link = normalizeString(name.toLowerCase())
    navigate(`/${slug}/${link}`)
  }


  return (
    <MegaMenuMobileContainer className={isOpen ? "open" : "close"}>
      <MegaMenuListWrapper>
        {MENU_MOBILE_LIST.map((item, id) => (
          <div key={"menu-item" + id.toString()}>
            <MenuItemWrapper>

              <MenuHeadingWrapper onClick={(e) => handleOpenSubMenu(e, id)}>
                <MenuItemTitle className={isOpenSubMenu.find(itemMenu => itemMenu === id.toString()) && "active"}>{item.title}</MenuItemTitle>
                {isOpenSubMenu.length > 0 && isOpenSubMenu.find(itemMenu => itemMenu === id.toString()) ?
                  <AiOutlineDown size={24} color={"#383838"}/> :
                  <AiOutlineRight size={24} color={"#383838"}/>}
              </MenuHeadingWrapper>

              {item.subMenu && (
                <MenuItemSubWrapper className={isOpenSubMenu.find(itemMenu => itemMenu === id.toString()) && "open"}>

                  {item.subMenu.map((subItem, id) => (
                    <MenuItemSubTitle onClick={(e) => handleNavigateHistory(e, subItem.link)} key={"submenu-item" + id.toString()}>{subItem.title}</MenuItemSubTitle>
                  ))}

                </MenuItemSubWrapper>
              )}

              {item.data && (
                <MenuItemSubWrapper className={isOpenSubMenu.find(itemMenu => itemMenu === id.toString()) && "open"}>
                  {item.data && item.data.getList && item.data.getList().map(itemSub => (
                    <MenuItemSubTitle
                      key={itemSub}
                      onClick={(e) => handleNavigate(e, item.slug, itemSub)}>
                      {itemSub}
                    </MenuItemSubTitle>
                  ))}
                  {item.data && item.data.list && item.data.list.map(itemSub => (
                    <MenuItemSubTitle
                      key={itemSub}
                      onClick={(e) => handleNavigate(e, item.slug, itemSub)}>
                      {itemSub}
                    </MenuItemSubTitle>
                  ))}
                </MenuItemSubWrapper>
              )}
            </MenuItemWrapper>
            <Divider/>
          </div>
        ))}
      </MegaMenuListWrapper>
      <BottomWrapper>
        <ExtraActionList>
          <ExtraActionItemBtn onClick={togglePopup}>
            <FaBook size={24} color={"#282828"}/>
            <ExtraActionItemTitle>Rendez-vous</ExtraActionItemTitle>
          </ExtraActionItemBtn>
          <DividerV/>
          <ExtraActionItemBtn onClick={(e) => handleNavigate(e, currentUser.isLoggedIn ? "account" : "login")} >
            <FaUserLock size={24} color={"#282828"}/>
            <ExtraActionItemTitle>Espace client</ExtraActionItemTitle>
          </ExtraActionItemBtn>
        </ExtraActionList>
        <WishListWrapper onClick={(e) => handleNavigate(e, "wishlist")}>
          <AiOutlineHeart size={20} color={"#78242C"}/>
          <WishListTitle>Ma liste de souhaits</WishListTitle>
        </WishListWrapper>
      </BottomWrapper>
    </MegaMenuMobileContainer>
  )
}
