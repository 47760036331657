import styled from "@emotion/styled"
import { colors, animationDuration, breakpoints, dimensions } from "../styles"
import { Link } from "gatsby"

export const MegaMenuBlock = styled.div`
  width: 100vw;
  background: white;
  @media (max-width: ${breakpoints.hd}px) {
    width: 100vw;
  }
  opacity: 0;
  z-index: 10;
  transition-duration: 0.5s;
  &.open {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    box-shadow: 1px 13px 15px 0px #00000005;
  }
  &.close {
    opacity: 0;
    transform: translateY(-400px);
    visibility: hidden;
  }
`

export const ContentWrapper = styled.div`
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 30px;
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const TitleColumn = styled.span`
  margin-top: 20px;
  font-weight: 500;
`

export const ThreeColumns = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 30px;
`

export const ListWrapper = styled.div`
  margin-right: 30px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 220px;
`

export const CollectionWrapper = styled.div`
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
`

export const CollectionMainWrapper = styled.div`
  padding: 0 20px;
  display: flex;
`

export const LinkList = styled.button`
  text-decoration: none;
  color: #232323;
  padding: 5px 10px;
  font-size: 1rem;
  cursor: pointer;
`

export const Divider = styled.div`
  width: 2px;
  height: 170px;
  background: ${colors.redWaskoll};
`

export const CollectionCard = styled.div`
  padding: 10px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SeeAllCollectionWrapper = styled.div`
  padding: 10px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const CollectionMainCard = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CollectionCardImage = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 5px;
`

export const CollectionMainCardImage = styled.div`
  width: 300px;
  height: 292px;
  border-radius: 5px;
`

export const CollectionCardTitle = styled.span`
  margin-top: 10px;
`