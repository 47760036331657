module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Lato:100,200,300,400,500,600,700","Lora:100,200,300,400,500,600,700","Cormorant:100,200,300,400,500,600,700","Montaga:100,200,300,400,500,600,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-graphql-component/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#4D1720","theme_color":"#000000","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"edf3d310d67f8284a562bc3a58c3e761"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
