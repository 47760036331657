import React, { useMemo } from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

export default ({ imageName }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allS3Object {
    nodes {
      Key
      localFile {
        childImageSharp {
          fluid(maxWidth: 1300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
      }
    `}
    render={data => {
      const getImageData = () => {
        const image = data.images.nodes.find(
          edge => edge.Key === imageName
        )
        if (!image) {
          return null
        }
        return <Img fluid={image.localFile.childImageSharp.fluid} />
      }

      return useMemo(() => getImageData(), [imageName])
    }}
  />
)
