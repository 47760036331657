import React, { useState } from "react"
import {
  BlockItemCart,
  BlockMetaProduct,
  DescriptionProductCart,
  TitleProductCart,
  TitleSize,
  ToggleEditSize
} from "./StyledItemCart"
import SizeProductBlock from "../../SingleProduct/DialogSizeProduct/"
import { Pane } from "evergreen-ui"

export default({productSrc, setSize}) => {
  const [editSize, setEditSize] = useState(false)

  const handleEditSize = (e, value) => {
    e.preventDefault()
    setSize(productSrc.id, value)
  }

  const showEditSize = (e) => {
    e.preventDefault()
    setEditSize(!editSize)
  }


  return (
    <BlockItemCart>
      <div style={{display: "flex", justifyContent: "flex-start"}}>
        <img style={{marginRight: "15px", maxHeight: "90px"}} alt={"image du produit"} src={process.env.IMAGE_ENDPOINT + productSrc.images[0].id + "_SMALL.png"} />
        <BlockMetaProduct>
          <TitleProductCart>
            {`${productSrc.attributeType} ${productSrc.attributeMetal} ${productSrc.attributeGem}`}
          </TitleProductCart>
          <DescriptionProductCart>
            {productSrc.description}
          </DescriptionProductCart>
          <Pane marginTop={12} display={"flex"} alignItems="center">
            <TitleSize>Taille: <span style={{fontWeight: "bold"}}>{productSrc.currentSize || "Aucune"}</span></TitleSize>
            <ToggleEditSize onClick={showEditSize}>(Modifier)</ToggleEditSize>
          </Pane>
        </BlockMetaProduct>
      </div>
      {editSize && <SizeProductBlock
        isOpen={editSize}
        callback={handleEditSize}
        productSizes={{min: productSrc.attributeSizeMin, max: productSrc.attributeSizeMax }}
        currentSize={productSrc.currentSize || 0}
      />}
    </BlockItemCart>
  )
}

