import styled from "@emotion/styled"
import { colors, animationDuration, breakpoints, dimensions } from "../styles"
import { Link } from "gatsby"

export const MegaMenuMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: -700px;
  background: white;
  overflow: scroll;
  width: 100vw;
  transition-duration: 0.5s;
  z-index: 9;
  height: 100vh;
  padding-bottom: 100px;
  padding-top: 10px;
  &.open {
    visibility: visible;
    top: 60px;
    opacity: 1;
    transition-duration: 0.5s;
  }
  &.close {
    top: -700px;
    opacity: 0;
    visibility: hidden;
    transition-duration: 0.5s;
  }
`

export const MegaMenuListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
`

export const ExtraActionList = styled.div`
  display: flex;
  justify-content: space-around;
  color: #292929;
  margin-top: 20px;
`

export const BottomWrapper = styled.div`
  bottom: 50px;
  width: 100%;
  margin-top: 2rem;
`

export const WishListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #292929;
  margin-top: 40px;
  text-decoration: none;
`

export const ExtraActionItem = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #292929;
  font-family: Cormorant;
  font-size: 1.2rem;
  font-weight: 400;
  flex: 1;
  text-align: center;
  text-decoration: none;
`

export const ExtraActionItemBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #292929;
  font-family: Cormorant;
  font-size: 1.2rem;
  font-weight: 400;
  flex: 1;
  text-align: center;
`

export const WishListTitle = styled.div`
  margin-left: 10px;
  color: #282828;
`



export const MenuItemWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 22px 0;
`

export const MenuHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  justify-content: space-between;
`

export const MenuItemTitle = styled.span`
  font-family: Cormorant;
  color: #383838;
  font-size: 1.6rem;
  font-weight: 400;
  &.active {
    color: ${colors.redWaskoll};
    font-weight: 500;
  }
`

export const MenuItemIconPlus = styled.span`
  font-family: Cormorant;
  color: #383838;
  font-size: 3rem;
  line-height: 0;
  font-weight: 400;
`

export const MenuItemSubWrapper = styled.div`
  display: flex;
  position: absolute;
  top: -250px;
  flex-direction: column;
  align-items: baseline;
  padding: 20px;
  justify-content: space-between;
  opacity: 0;
  transition-duration: 0.3s;
  visibility: hidden;
  &.open {
    visibility: visible;
    transition-duration: 0.3s;
    position: relative;
    top: 0px;
    opacity: 1;
  }
`

export const MenuItemSubTitle = styled.span`
  font-family: Cormorant;
  color: #383838;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 20px 0;
`

export const Divider = styled.div`
  height: 2px;
  width: 100%;
  background: #00000008;
  box-shadow: 0 0 9px 0px #0000000d;
`

export const DividerV = styled.div`
  width: 1px;
  border-radius: 100%;
  background: #f1f1f1;
`


