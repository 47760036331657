import { useSelector, useDispatch } from 'react-redux'

// Actions
import { setUser } from '../store/actions/setUser'
import { getDynamoDBUser } from "../utils/API"

const useAuth = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.user)

  const setUserSession = async userID => {
    let res = await getDynamoDBUser(userID || currentUser.id)
    dispatch(setUser(res.data.getUser))
  }



  return {
    setUserSession,
    currentUser,
  }
}

export default useAuth
