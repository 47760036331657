import styled from "@emotion/styled"
import { breakpoints, colors, dimensions } from "../styles"
import { Link } from "gatsby"

export const HeaderRoot = styled('header')`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: ${dimensions.headerHeight};
  border-bottom: 2px solid #2929290d;
  background: white;
  z-index: 9;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
    height: 60px;
    z-index: 10;
    background: black;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0px 2px 2px #00000045;
  }
`

export const HeaderRootSticky = styled('header')`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: ${dimensions.headerHeightSticky};
  border-bottom: 3px solid #2929290d;
  background: white;
  position: fixed;
  top: -50px;
  z-index: 9;
  visibility: hidden;
  box-shadow: 0px 6px 20px 0px rgba(0,0,0,.06);
  opacity: 0;
  transition: all .3s ease;
  &.visible {
    top: 0;
    visibility: visible;
    opacity: 1;
    display: inherit;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    display: none !important;
  }
`

export const LogoLink = styled(Link)`
  min-height: 80px;
  display: block;
  margin-top: 10px;
  z-index: 9;
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

export const ImageLogo = styled.img`
  max-height: 80px;
  @media (max-width: ${breakpoints.tablet}px) {
    
  }
`

export const ImageLogoSticky = styled.img`
  max-height: 40px;
  @media (max-width: ${breakpoints.tablet}px) {
    
  }
`

export const LogoLinkSticky = styled(Link)`
  min-height: 40px;
  display: block;
  margin-top: 20px;
  z-index: 20;
`

export const LogoMobileLink = styled(Link)`
  position: absolute;
  cursor: pointer;
  top: 18px;
  max-height: 24px;
  margin: auto;
`

export const TopMenu = styled('div')`
  max-width: 1200px;
  width: 100%;
  z-index: 9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #7c7c7c;
  font-size: 14px;
  padding-top: 20px;
  @media (max-width: ${breakpoints.hd}px) {
    width: 95vw;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
export const TopMenuLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`
export const TopMenuRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`
export const TopMenuContainerL = styled.div`
  width: 475px;
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
export const TopMenuContainerR = styled.div`
  width: 230px;
  display: flex;
  justify-content: space-between;
`

export const TopMenuContainerRSticky = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 20px;
  right: 30px;
`

export const TopNavLink = styled(Link)`
  display: flex;
  font-size: 1rem;
  align-items: center;
  font-family: Lora;
  align-items: center;
  font-weight: 400;
  text-transform: Capitalize;
  color: #444444;
  text-decoration: none;
  transition: all .2s ease;
  &:hover {
    color: black;
    transform: scale(1.01);
    font-weight: 500;
  }
`

export const TopNavLinkText = styled.span`
  font-weight: 400;
  margin-left: 10px;
  font-size: 1rem;
`

export const TopNavLinkSticky = styled(Link)`
  display: flex;
  font-family: Lora;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  text-transform: Capitalize;
  color: #444444;
  text-decoration: none;
  margin-right: 20px;
`

export const CartButton = styled.button`
  display: flex;
  font-size: 1.1rem;
  align-items: center;
  position: relative;
  outline: none;
  background: none;
  border: none;
  padding: none;
  cursor: pointer;
  font-weight: 300;
  text-transform: Capitalize;
  color: #444444;
  text-decoration: none;
  transition: all .2s ease;
  &:hover {
    color: black;
    transform: scale(1.01);
    font-weight: 500;
  }
`

export const CartButtonSticky = styled.button`
  display: flex;
  position: relative;
  outline: none;
  background: none;
  border: none;
  padding: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 300;
  text-transform: Capitalize;
  color: #444444;
  text-decoration: none;
  margin-right: 20px;
  padding: 0;
`

export const MenuButton = styled.button`
  display: flex;
  outline: none;
  position: ${props => props.isMobile ? "absolute" : "inherit"};
  left: 0;
  top: 0;
  height: 60px;
  padding-top: 4px;
  width: 60px;
  background: none;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  user-select:none;
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  };
  
`

export const TopBarMobileWrapper = styled.div`
  width: 100%;
  height: 60px;
  z-index: 10;
  background: black;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0px 2px 2px #00000045;
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  };
`

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const IconStyle = {
  color: "white", marginRight: "15px"
}

export const ButtonNav = styled.button`
  display: flex;
  outline: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  background: none;
`

export const CartCounter = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  width: 14px;
  height: 14px;
  line-height: 0px;
  border-radius: 50%;
  background: ${colors.warning};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-size: 8px;
  font-weight: bold;
`

export const StickyWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  background: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
`
