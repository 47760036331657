import { Auth } from 'aws-amplify'

export const isBrowser = () => typeof window !== 'undefined'

export async function SignIn({ email, password }) {
  return new Promise((resolve, reject) => {
    Auth.signIn(email, password)
      .then(data => {
        localStorage.setItem('isLoggedIn', data.username)
        resolve(data)
      })
      .catch(err => reject(err))
  })
}

export const SignUp = ({ password, email }) => {
  return new Promise((resolve, reject) => {
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email, // optional
      },
      validationData: [], //optional
    })
      .then(data => {
        resolve(data)
      })
      .catch(err => reject(err))
  })
}

export const isLoggedIn = () => {
  if (!isBrowser())
    return;
  const test = localStorage.getItem('isLoggedIn')
  return test
}

export const isAuthenticated = () => {
  return new Promise((resolve, reject) => {
    if (!isBrowser()) reject()
    Auth.currentAuthenticatedUser({ bypassCache: false })
      .then(user => {
        Auth.currentSession()
          .then(() => {
            localStorage.setItem('isLoggedIn', user.username)
            resolve(user)
          })
          .catch(err => reject(err))
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const confirmRegister = (username, code) => {
  return new Promise((resolve, reject) => {
    Auth.confirmSignUp(username, code, {
      forceAliasCreation: true,
    })
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}

export const resendConfirmation = username => {
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(username)
      .then(res => resolve(res))
      .catch(e => {
        reject(e)
      })
  })
}
