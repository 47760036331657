import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import useCart from "../../hooks/useCart"
import { useScrollPosition } from "../../hooks/useWindowScroll"

import NavMenu from "./NavMenu"

import {
  CartButton,
  CartButtonSticky,
  CartCounter,
  HeaderRoot,
  HeaderRootSticky, ImageLogo, ImageLogoSticky,
  LogoLink,
  LogoLinkSticky,
  MenuButton,
  TopMenu,
  TopMenuContainerL,
  TopMenuContainerR,
  TopMenuContainerRSticky,
  TopMenuLeft,
  TopMenuRight,
  TopNavLink,
  TopNavLinkSticky,
  TopNavLinkText
} from "./StyledHeader"

import { FiMenu, FiUser } from "react-icons/fi"
import { AiOutlineHeart, AiOutlineSearch, AiOutlineShopping } from "react-icons/ai"
import "../../assets/css/fontello.css"
import useAuth from "../../hooks/useAuth"
import TopBarMobile from "../HeaderMobile"
import { useSiteMetadata } from "../../hooks/useDataSite"
import { MENU_MOBILE_LIST } from "../shared/MenuMetadata"

const Header = ({ toggleCart, toggleModal, isOpenMenuMobile, toggleMenuMobile}) => {
  console.log("HEADER RENDER")
  const {listCartItems} = useCart()
  const { currentUser } = useAuth()
  const fetchCollection = useSiteMetadata("collection")
  const fetchMegaMenu = useSiteMetadata("megaMenu")
  const [listMenu, setListMenu] = useState(MENU_MOBILE_LIST)

  const [isSticky, setSticky] = useState(false)

  const fetchAndParseData = () => {
    let menuParsed = fetchMegaMenu.map(item => {
      return JSON.parse(item.data)
    })
    return MENU_MOBILE_LIST.map(menu => {
      let menuFind = menuParsed.find(item => item.parent === menu.slug)
      let collectionList = []
      if (menuFind) {
        menuFind.collections.map(collection => {
          let collectionFind = fetchCollection.find(item => item.id === collection)
          let collectionParsed = JSON.parse(collectionFind.data)
          collectionList.push(collectionParsed)
        })
      }
      if (menu.data) {
        menu.data.collectionList = collectionList
      }
      return menu
    })
  }

  useEffect(() => {
    setListMenu(fetchAndParseData())
  }, [])



  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -200 && !isSticky) setSticky(true)
    if (currPos.y > -200 && isSticky) setSticky(false)
  }, [isSticky])

  const imageSrc = useStaticQuery(graphql`
      query {
          logoDesktop: file(relativePath: { eq: "LOGO_HD.png" }) {
              childImageSharp {
                  fixed(height: 80) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          logoSticky: file(relativePath: { eq: "LOGO_HD_STICKY.png" }) {
              childImageSharp {
                  fixed(height: 35) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          icoAccount: file(relativePath: { eq: "icons/locked.png" }) {
              childImageSharp {
                  fixed(height: 22) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          icoSearch: file(relativePath: { eq: "icons/loupe1.png" }) {
              childImageSharp {
                  fixed(height: 22) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          icoCart: file(relativePath: { eq: "icons/shopping-bag.png" }) {
              childImageSharp {
                  fixed(height: 22) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          icoWishlist: file(relativePath: { eq: "icons/heart.png" }) {
              childImageSharp {
                  fixed(height: 22) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
      }
  `)
  return (
    <HeaderRoot>
      {!isSticky && (
        <TopMenu>
          <TopMenuLeft>
            <TopMenuContainerL>
              <TopNavLink to="/">
                <i className="icon-bell"/>
                <TopNavLinkText className="i-name">Services</TopNavLinkText>
              </TopNavLink>
              <TopNavLink to="/">
                <i className="icon-call"/>
                <TopNavLinkText className="i-name">+33 877 554 332</TopNavLinkText>
              </TopNavLink>
              <CartButton onClick={toggleModal}>
                <i className="icon-open-book"/>
                <TopNavLinkText className="i-name">Prendre Rendez-vous</TopNavLinkText>
              </CartButton>
            </TopMenuContainerL>
            <MenuButton isMobile={false}>
              <FiMenu style={{ fontSize: "1.4rem", color: "black" }}/>
            </MenuButton>
          </TopMenuLeft>
          <TopMenuRight>
            <TopMenuContainerR>
              {currentUser.isLoggedIn && currentUser.role === "admin" ? (
                <TopNavLink to={currentUser ? "/dashboard/" : "/login"}>
                  <Img fixed={imageSrc.icoAccount.childImageSharp.fixed}/>
                  <span style={{marginLeft: "8px"}} className="i-name">Mon Compte</span>
                </TopNavLink>
              ) : (
                <TopNavLink to={currentUser.isLoggedIn ? "/account/" : "/login"}>
                  <Img fixed={imageSrc.icoAccount.childImageSharp.fixed}/>
                  <span style={{marginLeft: "8px"}} className="i-name">Mon Compte</span>
                </TopNavLink>
              )}
              <TopNavLink to="/wishlist">
                <Img fixed={imageSrc.icoWishlist.childImageSharp.fixed}/>
              </TopNavLink>
              <CartButton onClick={toggleCart}>
                <Img fixed={imageSrc.icoCart.childImageSharp.fixed}/>
                {listCartItems.length > 0 &&  <CartCounter><span>{listCartItems.length}</span></CartCounter>}

              </CartButton>
              <TopNavLink to="/">
                <Img fixed={imageSrc.icoSearch.childImageSharp.fixed}/>
              </TopNavLink>
            </TopMenuContainerR>
          </TopMenuRight>
        </TopMenu>
      )}

      <LogoLink to="/" aria-label="Home page">
        <ImageLogo
          width={189}
          height={80}
          src={imageSrc.logoDesktop.childImageSharp.fixed.src}
          srcSet={imageSrc.logoDesktop.childImageSharp.fixed.srcSet}
        />
      </LogoLink>

      {!isSticky && (
        <NavMenu listMenu={listMenu} isSticky={isSticky}/>
      )}

      <HeaderRootSticky className={isSticky ? "visible" : null}>
        <LogoLinkSticky to="/" aria-label="Home page">
          <ImageLogoSticky
            width={154}
            height={35}
            src={imageSrc.logoSticky.childImageSharp.fixed.src}
            srcSet={imageSrc.logoSticky.childImageSharp.fixed.srcSet}
          />
        </LogoLinkSticky>
        <TopMenuContainerRSticky>
          <TopNavLinkSticky to={"/account/"}>
            <FiUser style={{ fontSize: "1.2rem"}}/>
          </TopNavLinkSticky>
          <TopNavLinkSticky to="/wishlist">
            <AiOutlineHeart style={{ fontSize: "1.3rem" }}/>
          </TopNavLinkSticky>
          <CartButtonSticky onClick={toggleCart}>
            <AiOutlineShopping style={{ fontSize: "1.3rem" }}/>
            {listCartItems.length > 0 &&  <CartCounter><span>{listCartItems.length}</span></CartCounter>}
          </CartButtonSticky>
          <TopNavLinkSticky to="/">
            <AiOutlineSearch style={{ fontSize: "1.3rem" }}/>
          </TopNavLinkSticky>
        </TopMenuContainerRSticky>
        <NavMenu listMenu={listMenu} isSticky={true}/>
      </HeaderRootSticky>

      <TopBarMobile isOpenMenu={isOpenMenuMobile}
                    toggleMenuMobile={toggleMenuMobile}
                    toggleCart={toggleCart}
      />

    </HeaderRoot>
  )
}

export default Header
