import styled from "@emotion/styled"
import { colors } from "../styles"

export const WrapperSideCart = styled.div`
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: white;
  transition-duration: 0.5s;
  &.openCartMobile {
    opacity: 1;
    visibility: visible;
  };
  &.closeCartMobile {
    opacity: 0;
    visibility: hidden;
  }
`

export const ButtonClose = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  outline: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  background: none;
`
export const TitleCartMobile = styled.h1`
  font-size: 1.2rem;
  text-align: center;
  font-weight: 300;
`

export const HeaderCart = styled.div`
  height: 75px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0px #0000000f;
`

export const WrapperListProduct = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TitleBtn = styled.span`
/*  font-family: Muli;*/
  font-size: 14px;
  margin-left: 10px;
`

export const DeleteButton = styled.button`
  display: flex;
  z-index: 10;
  position: absolute;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: ${colors.warning};
  font-size: 10px;
  background: #fafafa;
  padding: 10px;
  line-height: 0;
  font-size: 13px;
  font-weight: 100;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition-duration: 0.3s;
  transform: translateX(145px);
  &:hover {
    transform: translateX(0px);
    background: ${colors.warning};
    color: white;
  }
`

export const ViewButton = styled.button`
  display: flex;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: black;
  font-size: 10px;
  background: #fafafa;
  padding: 10px;
  line-height: 0;
  font-size: 13px;
  font-weight: 100;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  transition-duration: 0.3s;
  transform: translateX(97px);
  &:hover {
    transform: translateX(0px);
    background: ${colors.darkGrey};
    color: white;
  }
`
