import React from 'react'
import styled from "@emotion/styled"
import { breakpoints, colors } from "../styles"


export const Overlay = styled.div`
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: 100%;
  background: #9a9a9a7d;
  &.openCart {
    opacity: 1;
    visibility: visible;
  };
  &.closeCart {
    opacity: 0;
    visibility: hidden;
  }
`


export const WrapperSideCart = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 50;
  width: 460px;
  height: 100%;
  background: #F9F9FB;
  transition-duration: 0.5s;
  &.openCart {
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
  };
  &.closeCart {
    opacity: 0;
    transform: translateX(450px);
    visibility: hidden;
  }
  box-shadow: 0px -1px 20px 0px #0000003b;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`

export const HeaderCart = styled.div`
  height: 75px;
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0px #0000000f;
`

export const TitleCart = styled.h1`
  font-size: 1.5rem;
  margin-left: 15px;
`

export const TitleCartDesc = styled.h2`
  
`

export const DeleteButton = styled.button`
  display: flex;
  z-index: 10;
  position: absolute;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: ${colors.warning};
  font-size: 10px;
  background: #fafafa;
  padding: 10px;
  line-height: 0;
  font-size: 13px;
  font-weight: 100;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background: ${colors.warning};
    color: white;
  }
`
