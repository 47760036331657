import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import {
  ColumnWrapper,
  ContentWrapper,
  MegaMenuBlock,
  ThreeColumns,
  TitleColumn,
  MainWrapper,
  ListWrapper,
  CollectionWrapper,
  LinkList,
  Divider,
  CollectionCard,
  CollectionCardImage,
  CollectionCardTitle,
  CollectionMainWrapper,
  CollectionMainCard, CollectionMainCardImage, SeeAllCollectionWrapper
} from "./StyledMegaMenu"
import { colors } from "../styles"
import { normalizeString } from "../../utils/productHelpers"

import Image from "../Image"


export default ({ isOpen, currentMenu, listMenu, closeMenu }) => {
  const [dataMenu, setDataMenu] = useState([])

  useEffect(() => {
    if (currentMenu && listMenu) setDataMenu(listMenu.find(itemMenu => itemMenu.slug === currentMenu))
  }, [currentMenu, listMenu])

  const handlerNavigateHistory = (e, name) => {
    e.preventDefault()
    navigate(`/history#${name}`)
  }

  const handleNavigation = (e, item) => {
    e.preventDefault()
    closeMenu()
    let url = normalizeString(item)
    navigate(`/${currentMenu}/${url}`)
  }

  const imageSrc = useStaticQuery(graphql`
      query {
          historyFamily: file(relativePath: { eq: "MM_HISTORY_FAMILY.jpg" }) {
              childImageSharp {
                  fixed(height: 180, quality: 100) {
                      ...GatsbyImageSharpFixed_noBase64
                  }
              }
          },
          savoirFaire: file(relativePath: { eq: "MM_HISTORY_SAVOIRFAIRE.jpg" }) {
              childImageSharp {
                  fixed(height: 180, quality: 100) {
                      ...GatsbyImageSharpFixed_noBase64
                  }
              }
          },
          awards: file(relativePath: { eq: "MM_HISTORY_AWARDS.jpg" }) {
              childImageSharp {
                  fixed(height: 180, quality: 90) {
                      ...GatsbyImageSharpFixed_noBase64
                  }
              }
          },

      }
  `)

  const renderThreeColumns = () => (
    <ThreeColumns>
      <ColumnWrapper onClick={(e) => handlerNavigateHistory(e, "maison")}>
        <Img fixed={imageSrc.historyFamily.childImageSharp.fixed}/>
        <TitleColumn>UNE HISTOIRE DE <span style={{ color: colors.darkWaskoll }}>FAMILE</span></TitleColumn>
      </ColumnWrapper>
      <ColumnWrapper onClick={(e) => handlerNavigateHistory(e, "savoir-faire")}>
        <Img fixed={imageSrc.savoirFaire.childImageSharp.fixed}/>
        <TitleColumn>NOTRE <span style={{ color: colors.darkWaskoll }}>SAVOIR-FAIRE</span></TitleColumn>
      </ColumnWrapper>
      <ColumnWrapper onClick={(e) => handlerNavigateHistory(e, "distinctions")}>
        <Img fixed={imageSrc.awards.childImageSharp.fixed}/>
        <TitleColumn>LES <span style={{ color: colors.darkWaskoll }}>DISTINCTIONS</span></TitleColumn>
      </ColumnWrapper>
    </ThreeColumns>
  )

  return (
    <MegaMenuBlock className={isOpen ? "open" : "close"}>
        {currentMenu === "maison" ? (<ContentWrapper>{renderThreeColumns()}</ContentWrapper>) : (
          <MainWrapper>
            <ListWrapper>
              {dataMenu.data && dataMenu.data.list && dataMenu.data.list.map(item => (
                <LinkList key={item} onClick={(e) => handleNavigation(e, item)}>{item}</LinkList>
              ))}
              {dataMenu.data && dataMenu.data.getList && dataMenu.data.getList().map(item => (
                <LinkList key={item} onClick={(e) => handleNavigation(e, item)}>{item}</LinkList>
              ))}
            </ListWrapper>
            <Divider/>
            {dataMenu.data && (
              <CollectionWrapper>
                <CollectionCard>
                  <CollectionCardImage>
                    <Image imageName={`${dataMenu.data.collectionList[0].data.imageId}_SMALL.png`}/>
                  </CollectionCardImage>
                  <CollectionCardTitle>{`Collection ${dataMenu.data.collectionList[0].data.name}`}</CollectionCardTitle>
                </CollectionCard>
                <CollectionCard>
                  <CollectionCardImage>
                    <Image imageName={`${dataMenu.data.collectionList[1].data.imageId}_SMALL.png`}/>
                  </CollectionCardImage>
                  <CollectionCardTitle>{`Collection ${dataMenu.data.collectionList[1].data.name}`}</CollectionCardTitle>
                </CollectionCard>
                <CollectionCard>
                  <CollectionCardImage>
                    <Image imageName={`${dataMenu.data.collectionList[2].data.imageId}_SMALL.png`}/>
                  </CollectionCardImage>
                  <CollectionCardTitle>{`Collection ${dataMenu.data.collectionList[2].data.name}`}</CollectionCardTitle>
                </CollectionCard>
                <SeeAllCollectionWrapper>
                  <CollectionCardTitle>Voir toutes les collections</CollectionCardTitle>
                </SeeAllCollectionWrapper>
              </CollectionWrapper>
            )}

            <CollectionMainWrapper>
              {dataMenu.data && (
                <CollectionMainCard>
                  <CollectionMainCardImage>
                    <Image imageName={`${dataMenu.data.collectionList[3].data.imageId}_MEDIUM.png`}/>
                  </CollectionMainCardImage>
                  <CollectionCardTitle>{`Collection ${dataMenu.data.collectionList[3].data.name}`}</CollectionCardTitle>
                </CollectionMainCard>
              )}
            </CollectionMainWrapper>
          </MainWrapper>
        )}

    </MegaMenuBlock>
  )
}
