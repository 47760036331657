export const initialProduct = {
  attributeType: "Bague",
  attributeMetal: "",
  attributeGem: "",
  price: "",
  ref: "",
  singleGem: false,
  images: [],
  description: "",
  collection: "",
  preview: [],
  plural: false,
  genre: "lui",
  priceStartFrom: false,
}

export const attributeGemWeightList = [
    '0.30',
    '0.50',
    '0.70',
    '1',
  ]

export const collectionsList = () => ([
  'Flamme',
  'Eternel',
  'Chemin du coeur',
  'Union',
  'Sucre',
  'Déclaration',
  'Parfums du monde',
  'Wave',
  "Lumière d'été",
  "Délicatesse",
  "New Wave",
  "Never give in",
  "Fusion",
  "Dentelle",
  "Tendresse",
  "French Hug",
  "Luminescence",
  "Diamond Chain",
  "Promesse",
  "Intemporels",
  "Pièce d’exception" ,
  "Solitaire",
  "Alliance"
])

export const attributeTypeList = () => ([
  'Bague',
  'Bague Fiançaille',
  "Boucles d'oreilles",
  "Bracelet",
  "Alliance",
  "Chevalière",
  "Collier",
  "Solitaire",
  'Pendentif'
])

export const attributeMetalList = () => ([
  'Or Jaune',
  'Or Rose',
  'Or Blanc',
  'Platine',
  'Bicolore'
])

export const attributeGemList = () => ([
  'Diamant',
  'Saphir',
  'Saphir Rose',
  'Saphir Jaune',
  'Saphir Violet',
  'Saphir Vert',
  'Saphir Blanc',
  'Saphir Orange',
  'Saphir Rouge',
  'Saphirs Multicolores',
  'Rubis',
  'Emeraude',
])

export const allFilterList = () => ([
  {
    name: "collection",
    attribute: "collection",
    getData: () => collectionsList()
  },
  {
    name: "metal",
    attribute: "attributeMetal",
    getData: () => attributeMetalList()
  },
  {
    name: "pierre",
    attribute: "attributeGem",
    getData: () => attributeGemList()
  },
  {
    name: "bijoux",
    attribute: "attributeType",
    getData: () => attributeTypeList()
  },
])

export const attributeGenre = () => ([
  'Lui',
  'Elle',
  'Les deux'
])

export const attributeSize = () => {
  let attributes = []
  for (let i = 45; i < 64; i++) {
    attributes.push(i)
  }
  return attributes
}

const allPreviewItems = [
  {
    id: 0,
    type: "Bague",
    gems: "Diamants",
    metal: "Or Blanc"
  },
  {
    id: 1,
    type: "Bague",
    gems: "Diamants",
    metal: "Or Rose"
  },
  {
    id: 2,
    type: "Bague",
    gems: "Saphirs Multicolores",
    metal: "Or Blanc"
  },
]
