import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import {
  ButtonNav,
  ButtonWrapper,
  TopBarMobileWrapper, LogoMobileLink,
  MenuButton,
} from "../Header/StyledHeader"

import { IoMdClose } from "react-icons/io"
import { FiMenu } from "react-icons/fi"
import { BsBag, BsSearch } from "react-icons/bs"
import MegaMenuMobile from "../MegaMenuMobile"

const Header = ({ toggleCart, toggleMenuMobile, isOpenMenu }) => {

  const imageSrc = useStaticQuery(graphql`
      query {
          logoMobile: file(relativePath: { eq: "LOGO_PICTO.png" }) {
              childImageSharp {
                  fixed(height: 28) {
                      ...GatsbyImageSharpFixed_noBase64
                  }
              }
          }
      }
  `)

  return (
    <TopBarMobileWrapper>
      <MenuButton onClick={toggleMenuMobile} isMobile={true}>
        {isOpenMenu ? (<IoMdClose style={{ fontSize: "2rem", color: "white" }}/>) : (<FiMenu style={{ fontSize: "2rem", color: "white" }}/>)}

      </MenuButton>
      <LogoMobileLink to="/" aria-label="Home page">
        <Img fixed={imageSrc.logoMobile.childImageSharp.fixed}/>
      </LogoMobileLink>
      <ButtonWrapper>
        <BsSearch size={19} color={"white"} style={{ marginRight: "20px" }}/>
        <ButtonNav onClick={toggleCart}>
          <BsBag size={20} color={"white"}/>
        </ButtonNav>
      </ButtonWrapper>
    </TopBarMobileWrapper>
  )

}

export default Header
