/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

import useAuth from "../hooks/useAuth"
import { isLoggedIn } from "../utils/auth"

import SiteMetadata from "./shared/SiteMetadata"

import { css, Global } from "@emotion/core"
import styled from "@emotion/styled"
import Header from "./Header"
import Footer from "./Footer"
import FooterServices from "./FooterServices"
import SideCart from "./SideCart"
import MegaMenuMobile from "./MegaMenuMobile"
import TopBarMobile from "./HeaderMobile"
import { breakpoints, colors } from "./styles"
import Popup from "./Popup"

const Main = styled("main")`
  margin: auto;
  @media (max-width: ${breakpoints.tablet}px) {
   padding-top: 60px;
  };
  `

const Layout = ({ children }) => {
  console.info("LAYOUT RENDER")

  const { currentUser, setUserSession } = useAuth()

  const [isOpenSideCart, setOpenSideCart] = useState(false)
  const [isOpenModal, setOpenModal] = useState(false)
  const [isOpenMenuMobile, setOpenMenuMobile] = useState(false)
  const [isOpenPopupMobile, setOpenPopupMobile] = useState(false)
  const [isOpenCartMobile, setOpenCartMobile] = useState(false)


  useEffect(() => {
    if (isLoggedIn() && !currentUser.isLoggedIn) {
      setUserSession(isLoggedIn())
    }
  }, [currentUser])

  const handleToggleCart = (e) => {
    e.preventDefault()
    setOpenSideCart(!isOpenSideCart)
  }

  const handleToggleModal = (e) => {
    e.preventDefault()
    setOpenModal(!isOpenModal)
  }

  const handleToggleCartMobile = (e) => {
    e.preventDefault()
    setOpenCartMobile(!isOpenCartMobile)
  }

  const handleOpenMenuMobile = (e) => {
    e.preventDefault()
    setOpenMenuMobile(!isOpenMenuMobile)
  }

  const handleCloseMenuMobile = (e) => {
    e.preventDefault()
    setOpenMenuMobile(!isOpenMenuMobile)
  }

  const handleOpenPopupMobile = (e) => {
    e.preventDefault()
    setOpenPopupMobile(!isOpenPopupMobile)
  }

  const childrenWithProps = useMemo(() => React.Children.map(children, id =>
    React.cloneElement(children, { toggleCart: handleToggleCart, toggleModal: handleToggleModal, isMobile: false, key: id })
  ), [children])


  const renderPopup = useMemo(() =>
      <Popup
        isOpen={isOpenModal}
        closeModal={handleToggleModal}
        modalType={"rendez-vous"}
        data={{
          title: "PRENDRE RENDEZ-VOUS",
          btnTitle: "Prendre rendez-vous",
          subTitle: "La maison Waskoll se tient à votre disposition pour convenir d'un rendez-vous."
        }}
      />
    ,[isOpenModal])

  const renderHeader = useMemo(() =>
      <Header
        toggleCart={handleToggleCart}
        toggleModal={handleToggleModal}
        isOpenMenuMobile={isOpenMenuMobile}
        toggleMenuMobile={handleOpenMenuMobile}
        toggleCartMobile={handleToggleCartMobile}
      />
    ,[isOpenMenuMobile])


  return (
    <>
      <Global
        styles={css`
          html {
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
          }
          *,
          *:before,
          *:after {
            box-sizing: inherit;
          }
          body {
            font-size: 16px;
            line-height: 1.375;
            margin: 0 auto;
            font-family: Lora;
            overflow: hidden;
            overflow-y: ${isOpenSideCart || isOpenModal || isOpenMenuMobile ? "hidden": "scroll"};
            background: ${colors.background};
          }
          h1 {
            font-family: Lora;
          }
          button {
            font-family: Lora;
            border: none;
            outline: none;
            background: none;
            padding: 0;
          }
        `}
      />
      <SiteMetadata/>
      <SideCart isOpen={isOpenSideCart} closeCart={handleToggleCart}/>
      <MegaMenuMobile closeMenu={handleCloseMenuMobile}
                      togglePopup={handleToggleModal}
                      isOpen={isOpenMenuMobile}
      />
      {renderPopup}
      {renderHeader}
      <Main>{childrenWithProps}</Main>
      <FooterServices toggleModal={handleToggleModal}/>
      <Footer/>
    </>
  )

}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
