import { AiOutlineIdcard, AiOutlineHome, AiOutlineShopping, AiOutlineHeart } from "react-icons/ai"
import { colors } from "../styles"
import React from "react"
import { MenuItemSubTitle } from "../MegaMenuMobile/StyledMegaMenuMobile"
import { attributeTypeList } from "./ProductMetadata"

export const MENU_LIST = [
  {
    name: "Informations personnelles",
    link: "account/profile",
    icon: () => <AiOutlineIdcard size={30} color={colors.darkGrey}/>,
    subMenuList: [
      {name: "Modifiez votre profil", link: "account/edit-profile"},
      {name: "Modifiez votre adresse e-mail", link: "account/edit-email"},
      {name: "Modifiez votre mot de passe", link: "account/edit-password"},
      {name: "Modifiez votre adresse de livraison", link: "account/edit-shipping"},
    ]
  },
  {
    name: "Mes commandes",
    link: "account/order",
    icon: () => <AiOutlineShopping size={25} color={colors.darkGrey}/>
  },
  {
    name: "Ma liste d'envies",
    link: "account/wishlist",
    icon: () => <AiOutlineHeart size={25} color={colors.darkGrey}/>
  },
]

export const MENU_MOBILE_LIST = [
  {
    title: "La Maison",
    slug: "maison",
    link: "",
    subMenu: [
      {
        title: "Une histoire de famille",
        link: "maison",
      },
      {
        title: "Notre Savoir-faire",
        link: "savoir-faire",
      },
      {
        title: "Les Distinctions",
        link: "distinctions"
      },
    ]
  },
  {
    title: "Pièces d'exception",
    slug: "exception",
    link: "",
  },
  {
    title: "Joaillerie",
    slug: "joaillerie",
    link: "",
    data: {
      getList: () => attributeTypeList().filter(item => item !== "Alliance" && item !== "Bague Fiançaille"),
      list: ['Créations sur mesure', 'Sélection e-boutique'],
      collectionList: []
    }
  },
  {
    title: "Mariage",
    slug: "mariage",
    link: "",
    data: {
      list: ['Bague Fiançaille', 'Alliance'],
      collectionList: []
    }
  },
  {
    title: "Cadeaux",
    slug: "cadeaux",
    link: "",
    data: {
      list: ['Sur mesure', 'Inspiration'],
      collectionList: []
    }
  },

]
