import { keyframes } from '@emotion/core'
import styled from "@emotion/styled"

/*
 * NOTE: use a six-character hex code for all colors to allow alpha channel
 * adjustment without adding extra vars and/or a color manipulation lib.
 *
 * Example:
 *    // use the brand color at 25% opacity
 *    border-color: ${colors.brand}40;
 */
export const colors = {
  brandDarker: '#221133',
  brandDark: '#442266',
  brand: '#663399',
  brandBright: '#e0d6eb',
  brandLight: '#f5f3f7',
  brandLighter: '#fbfafc',
  lightest: '#ffffff',
  darkest: '#4d4058',
  text: '#333333',
  textMild: '#555555',
  textLight: '#7e718a',
  textLighter: '#aaaaaa',
  lilac: `#8c65b3`,
  accent: `#ffb238`,
  error: `#ec1818`,
  lemon: `#ffdf37`,
  warning: `#D8636F`,
  darkGrey: `#292929`,
  softGrey: `#464646`,
  redWaskoll: `#78242C`,
  softWaskoll: `#F6F2F2`,
  darkWaskoll: `#4D1720`,
  ebene: `#2B1A10`,
  softBeige: `#db709329`,
  beige: `#db709361`,
  pink: '#db7093',
  pinkDark: '#9c415f',
  marron: `#A3795E`,
  background: '#f8f8fb'
}

export const animationDuration = {
  slow: 0.6,
  normal: 0.3,
  fast: 0.2,
}

export const spacing = {
  '3xs': 2,
  '2xs': 4,
  xs: 8,
  sm: 12,
  md: 16,
  lg: 24,
  xl: 32,
  '2xl': 40,
  '3xl': 48,
}

export const breakpoints = {
  mobile: 400,
  phablet: 640,
  tablet: 850,
  desktop: 1050,
  hdesktop: 1150,
  hd: 1300,
}

export const radius = {
  default: 2,
  large: 4,
}

export const dimensions = {
  contentWidth: '1200px',
  sliderWidth: '1300px',
  contentWidthMedium: '800px',
  containerLogin: '700px',
  headerHeight: '195px',
  headerHeightSticky: '115px',
  cartWidthDesktop: '400px',
  contributorAreaWidth: {
    closedDesktop: '60px',
    openDesktop: '340px',
    openHd: '420px',
  },
  contributorAreaBarHeight: '50px',
  pictureBrowserAction: {
    widthDesktop: '200px',
    heightMobile: '80px',
  },
}

export const scrollbarStyles = {
  WebkitOverflowScrolling: `touch`,
  '&::-webkit-scrollbar': { width: `6px`, height: `6px` },
  '&::-webkit-scrollbar-thumb': { background: colors.brandBright },
  '&::-webkit-scrollbar-thumb:hover': { background: colors.lilac },
  '&::-webkit-scrollbar-track': { background: colors.brandLight },
}

export const Separation = styled.div`
    height: 1px;
    width: 100%;
    background: rgb(250, 250, 250);
    margin-top: 10px;
    margin-bottom: 5px;
  `

export const megaMenuOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

export const megaMenuEntry = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
  
`
