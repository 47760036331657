// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: process.env.AWS_REGION,
  aws_cognito_identity_pool_id: process.env.AWS_cognito_identity_pool_id,
  aws_cognito_region: process.env.AWS_REGION,
  aws_user_pools_id: process.env.AWS_user_pools_id,
  aws_user_pools_web_client_id: process.env.AWS_user_pools_web_client_id,
  oauth: {},
  aws_appsync_graphqlEndpoint: process.env.AWS_appsync_graphqlEndpoint,
  aws_appsync_region: process.env.AWS_REGION,
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: process.env.AWS_appsync_apiKey,
  aws_user_files_s3_bucket: process.env.AWS_user_files_s3_bucket,
  aws_user_files_s3_bucket_region: process.env.AWS_REGION
}

export default awsmobile
