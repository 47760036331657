import { graphql, useStaticQuery } from "gatsby"
import { useMemo } from "react"

export const useSiteMetadata = (type) => {
  //console.log("RENDER HOOK", type)
  const data = useStaticQuery(
    graphql`
      query getListDataSite {
        WaskollData {
          listDataSite {
            items {
              data
              id
              type
            }
          },
        }
      }
    `
  )

  const filterData = () => {
    let res = data.WaskollData.listDataSite.items.filter(item => item.type === type)
    if (res.length === 0) {
      return []
    }
    else if (res.length < 2) {
      return {
        ...res[0],
        data: JSON.parse(res[0].data)
      }
    }
    else {
      return res
    }
  }

  return useMemo(() => filterData(), [type])


}